@font-face {
  font-family: 'Badger';
  src: url('/assets/BadgerRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Monument';
  src: url('/assets/MonumentExtended-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Denton';
  src: url('/assets/Denton.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  display: flex;
  align-items: center;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.8);
}

body,
html {
  margin: 0;
  padding: 0;
  position: relative;
}

canvas {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}

.bg {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: transparent url('/assets/noise-transparent.png') repeat 0 0;
  background-repeat: repeat;
  animation: bg-animation 0.3s infinite;
  opacity: 0.5;
  z-index: -1;
  pointer-events: none;
}

h1 {
  margin: 0;
  position: relative;
  mix-blend-mode: lighten;
  font-size: 40px;
  font-weight: normal;
  color: white;
  transform: translateZ(0);
}

h1 span {
  display: block;
}

h1 .badger {
  top: -0.25em;
}

h2 {
  font-family: Denton, serif;
  font-size: 36px;
  font-weight: normal;
  line-height: 0.9;
}

p {
  font-size: 14px;
  line-height: 1.33;
}

img {
  display: block;
}

.monument {
  font-family: Monument, sans-serif;
  font-size: 1.35em;
  line-height: 75%;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.badger {
  position: relative;
  font-size: 1em;
  font-family: Badger, sans-serif;
  text-transform: uppercase;
  color: black;
}

.badger:after {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  content: attr(data-content);
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: white;
}

.app {
  max-width: 1200px;
  padding: 16px;
  margin: 0 auto;
}

.header {
  display: grid;
  gap: 16px;
}

.logo img {
  max-width: 230px;
}

.intro {
  margin: 48px 0;
  max-width: 400px;
}

.articles {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footnote {
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
}

article {
  background: white;
  color: black;
  padding: 24px;
  border-radius: 16px;
}

article h2 {
  margin: 0;
  opacity: 0.9;
}

article p {
  margin: 12px 0 0;
  opacity: 0.75;
}

@keyframes bg-animation {
  0% {
    background-position: 0;
  }
  24% {
    background-position: 0;
  }
  25% {
    background-position: -25% -25%;
  }
  49% {
    background-position: -25% -25%;
  }
  50% {
    background-position: -15% -15%;
  }
  73% {
    background-position: -15% -15%;
  }
  74% {
    background-position: 30% 30%;
  }
  99% {
    background-position: 30% 30%;
  }
}

@media (min-width: 400px) {
  .articles {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 800px) {
  .app {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .articles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  /* .header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .intro {
    margin-top: 0;
    grid-area: 1;
    padding-right: 16px;
  }

  .logo {
    grid-column: span 2;
  }

  .logo img {
    max-width: none;
    width: 100%;
  } */
}
