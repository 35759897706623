@font-face {
  font-family: Badger;
  src: url("BadgerRegular.74c76a06.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Monument;
  src: url("MonumentExtended-Regular.9b15454a.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Denton;
  src: url("Denton.deefb9c1.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  color: #fffc;
  align-items: center;
  min-height: 100vh;
  font-family: Inter, sans-serif;
  display: flex;
}

body, html {
  margin: 0;
  padding: 0;
  position: relative;
}

canvas {
  z-index: -1;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
}

.bg {
  opacity: .5;
  z-index: -1;
  pointer-events: none;
  background: url("noise-transparent.0754861a.png");
  width: 100%;
  height: 100%;
  animation: .3s infinite bg-animation;
  position: fixed;
  inset: 0;
}

h1 {
  mix-blend-mode: lighten;
  color: #fff;
  margin: 0;
  font-size: 40px;
  font-weight: normal;
  position: relative;
  transform: translateZ(0);
}

h1 span {
  display: block;
}

h1 .badger {
  top: -.25em;
}

h2 {
  font-family: Denton, serif;
  font-size: 36px;
  font-weight: normal;
  line-height: .9;
}

p {
  font-size: 14px;
  line-height: 1.33;
}

img {
  display: block;
}

.monument {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: Monument, sans-serif;
  font-size: 1.35em;
  line-height: 75%;
}

.badger {
  text-transform: uppercase;
  color: #000;
  font-family: Badger, sans-serif;
  font-size: 1em;
  position: relative;
}

.badger:after {
  z-index: -1;
  content: attr(data-content);
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: white;
  position: absolute;
  top: 0;
  left: 0;
}

.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;
}

.header {
  gap: 16px;
  display: grid;
}

.logo img {
  max-width: 230px;
}

.intro {
  max-width: 400px;
  margin: 48px 0;
}

.articles {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.footnote {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  font-size: 12px;
}

article {
  color: #000;
  background: #fff;
  border-radius: 16px;
  padding: 24px;
}

article h2 {
  opacity: .9;
  margin: 0;
}

article p {
  opacity: .75;
  margin: 12px 0 0;
}

@keyframes bg-animation {
  0% {
    background-position: 0;
  }

  24% {
    background-position: 0;
  }

  25% {
    background-position: -25% -25%;
  }

  49% {
    background-position: -25% -25%;
  }

  50% {
    background-position: -15% -15%;
  }

  73% {
    background-position: -15% -15%;
  }

  74% {
    background-position: 30% 30%;
  }

  99% {
    background-position: 30% 30%;
  }
}

@media (width >= 400px) {
  .articles {
    grid-template-columns: 1fr 1fr;
    display: grid;
  }
}

@media (width >= 800px) {
  .app {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .articles {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
  }
}

/*# sourceMappingURL=index.11d8807b.css.map */
